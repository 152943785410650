export const ROUTE_TOKENS = {
  ADMIN: {
    ADMIN: 'admin',
    METRICS: 'metrics',
    ORDERS_AND_TICKETS: {
      ORDERS_AND_TICKETS_BASE: 'orders-and-tickets',
      ORDERS_LIST: 'orders',
      STATISTICS: 'statistics',
      TICKETS_LIST: 'tickets',
    },
    PROJECTS: 'projects',
    PROMOCODES: {
      DISCOUNTS: 'discounts',
      PROMOCODES_BASE: 'promocodes',
      PROMOCODES_LIST: 'list',
    },
    SETTINGS: 'settings',
    SHOP: {
      LETTER_TEMPLATES: 'letter-templates',
      PAYMENT_METHODS: 'payment-methods',
      SETTINGS: 'settings',
      SHOP_BASE: 'shop',
      TICKET_TEMPLATES: 'ticket-templates',
    },
    TICKETS_AND_PRICES: 'tickets-and-prices',
  },
  ARCHIVE: {
    ARCHIVE_BASE: 'archive',
  },
  DISPOSABLE_LOGIN: 'disposable-login',
  EXHIBITION: {
    DOCUMENTS: 'documents',
    PREPARATION: 'preparation',
    SETTINGS: 'settings',
    STAGES: 'stages',
  },
  EXHIBITIONS: 'exhibitions',
  FAQ: 'faq',
  LOGIN: 'login',
  NOTIFICATION: 'notification',
  PARTICIPATION: {
    PARTICIPATION_BASE: 'effective-participation',
    REQUIREMENTS: 'requirements',
    SCHEDULE: 'schedule',
  },
  PASSWORD_RECOVERY: 'password-recovery',
  PROFILE: {
    NOTIFICATIONS: 'notifications',
    PROFILE_BASE: 'profile',
    SETTINGS: 'settings',
  },
  REGISTRATION: 'registration',
  REGISTRATION_SUCCESS: 'registration-success',
  RESTORE_PASSWORD: 'restore_password',
  RESTORE_PASSWORD_SUCCESS: 'restore_password_success',
  SETTINGS: {
    BADGES: 'badges',
    BRANDS: 'brands',
    PINS: 'pins',
    PROMOCODES: 'promocodes',
    STANDS: 'stands',
  },
} as const;
