export const ROUTE_PARAMS_TOKENS = {
  BADGE_ID: 'badgeId',
  BRAND_ID: 'brandId',
  DISCOUNT_ID: 'discountId',
  EXHIBITION_ID: 'exhibitionId',
  LETTER_TEMPLATE_ID: 'letterTemplateId',
  NOTIFICATION_ID: 'notificationId',
  ORDER_ID: 'orderId',
  PAYMENT_ID: 'paymentId',
  PROJECT_ID: 'projectId',
  PROMOCODE_ID: 'promocodeId',
  STAGE_ID: 'stageId',
  STAND_ID: 'standId',
  TICKET_ID: 'ticketId',
  TICKET_TEMPLATE_ID: 'ticketTemplateId',
} as const;
